html,
body {
  background-color: #f3f5f8;
}
.menu-version {
  padding: 1rem 0 1rem 28px;
  color: rgba(255, 255, 255, 0.85) !important;
  font-size: 0.9rem;
}
.manage-layout {
  height: 100vh;
}
.manage-layout .slider-content .logo {
  margin: 20px 0 36px 64px;
}
.manage-layout .slider-content .logo img {
  height: 38px;
  width: 70px;
}
.manage-layout .ant-layout-sider-collapsed .slider-content .logo {
  margin: 20px 10px 41.43px 10px;
}
.manage-layout .ant-layout-sider-collapsed .slider-content .logo img {
  height: auto;
  width: 60px;
}
.manage-layout .slider-horizontal .slider-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(132deg, #2d52d2 0%, #6789f3 100%);
}
.manage-layout .slider-horizontal .slider-content .menu-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.manage-layout
  .slider-horizontal
  .slider-content
  .menu-content
  .sidebar-menu
  .ant-menu-item:hover,
.manage-layout
  .slider-horizontal
  .slider-content
  .menu-content
  .sidebar-menu
  .ant-menu-submenu-title:hover {
  background: linear-gradient(122deg, #678cfc 0%, #537be9 100%) !important;
}
.manage-layout
  .slider-horizontal
  .slider-content
  .menu-content
  .sidebar-menu
  .ant-menu-item-selected,
.manage-layout-drawer .ant-menu-item-selected {
  background: linear-gradient(122deg, #678cfc 0%, #537be9 100%) !important;
  font-weight: 600;
}
.manage-layout .sidebar-menu .ant-menu-sub,
.manage-layout-drawer .ant-menu-sub {
  background: #3259e0 !important;
}
.manage-layout .sidebar-menu .ant-menu-sub .ant-menu-item,
.manage-layout-drawer .ant-menu-sub .ant-menu-item {
  padding-left: 64px !important;
}
.manage-layout .slider-horizontal .ant-layout-sider-trigger {
  background: rgb(102, 139, 252);
  position: absolute;
  bottom: 0;
}
.manage-layout .header-content {
  position: sticky;
  top: 0;
  z-index: 98;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 14px;
  color: rgba(0, 0, 0, 0.85);
}
.manage-layout .header-content .menu-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.manage-layout .header-content .menu-content .menu-right .header-button {
  margin-left: 24px;
}
.manage-layout .header-button:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.manage-layout .header-content .header-user-button {
  display: flex;
  align-items: center;
  padding: 7px 10px;
  border-radius: 4px;
}
.manage-layout .header-content .header-user-button .ant-image {
  width: 32px;
  height: 32px;
  display: flex;
}
.manage-layout .header-content .header-user-button .header-user-name {
  max-width: 8em;
  margin: 0 12px;
  display: flex;
  align-items: center;
}
.manage-layout .header-workspace-button {
  margin-left: 0;
  padding: 7px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.manage-layout .layout-content {
  height: calc(100vh - 60px);
  overflow: auto;
  width: 100%;
  padding: 16px 24px;
  position: relative;
}
.manage-layout .layout-content-box {
  margin-top: 16px;
  padding: 22px 16px;
  background: #fff;
  overflow: auto;
  border-radius: 6px;
}
.manage-layout .common-horizontal-padding {
  padding-left: 22px;
  padding-right: 22px;
}

@media screen and (min-width: 993px) {
  .manage-layout .horizontal-hidden {
    display: none !important;
  }
}
@media screen and (max-width: 992px) {
  .manage-layout {
    background: #fff !important;
  }
  .manage-layout .slider-horizontal {
    display: none;
  }
  .manage-layout .layout-content {
    padding: 10px 10px;
  }
  .manage-layout .layout-content-box {
    margin-top: 10px;
    padding: 12px 10px;
  }
  .manage-layout .common-horizontal-padding {
    padding-left: 10px;
    padding-right: 10px;
  }
  .manage-layout .vertical-hidden {
    display: none !important;
  }
  .manage-layout .header-content {
    padding: 0 5px;
  }
  .manage-layout .header-content .menu-content .menu-right .header-button {
    margin-left: 0;
  }
}
.manage-layout .goback-btn {
  background: transparent !important;
  border: none;
  color: rgba(0, 0, 0, 0.45);
}
.manage-layout .page-header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  align-content: flex-start;
}
.manage-layout .notification-tab {
  display: flex;
}
.manage-layout .notification-type-tab {
  padding-right: 20px;
  margin-top: 5px;
}
.manage-layout .notification-type-tab .ant-tabs-tab-active {
  background: rgba(42, 97, 255, 0.06);
  border: none !important;
}
.manage-layout .notification-type-tab .ant-tabs-ink-bar {
  display: none;
}
.manage-layout .notification-tab .ant-tabs-content-holder {
  width: 20px;
}
.luckysheet-scrollbar-ltr {
  z-index: 999 !important;
}
.manage-layout .luckysheet-loading-mask {
  z-index: 999;
}
.manage-layout .luckysheet-bottom-controll-row {
  z-index: 989;
}
.goback-btn {
  background: transparent !important;
  border: none;
  color: rgba(0, 0, 0, 0.45);
}
.common_pagetitle ol {
  justify-content: flex-end;
}
.common_pagetitle.ant-breadcrumb .ant-breadcrumb-separator,
.common_pagetitle li .ant-breadcrumb-link span {
  color: rgba(0, 0, 0, 0.25);
}
.common_pagetitle li .ant-breadcrumb-link a:hover span {
  color: #638bff;
}
.common_pagetitle li .ant-breadcrumb-link a {
  background-color: transparent;
}
.common_pagetitle li:last-child .ant-breadcrumb-link span {
  color: rgba(0, 0, 0, 0.45);
}

.manage-layout table tr.ant-table-row-level-0:has(.anticon-down-circle) {
  background: rgba(42, 97, 255, 0.03);
}
.manage-layout .ant-table-thead > tr > th::before {
  background-color: transparent !important;
}
.manage-layout table tr.ant-table-expanded-row {
  background: #fff;
  box-shadow: inset 0px 0px 8px 0px rgba(42, 97, 255, 0.3);
  filter: blur(0px);
  padding: 7px min(5vw, 55px);
}
.manage-layout tr.ant-table-expanded-row > td {
  background: transparent !important;
}

.manage-layout .ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.65);
}

.manage-layout .ant-pagination:not(.ant-pagination-mini) .ant-pagination-item,
.manage-layout
  .ant-pagination:not(.ant-pagination-mini)
  .ant-pagination-item-link {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
}
.manage-layout
  .ant-pagination:not(.ant-pagination-mini)
  .ant-pagination-item:hover,
.manage-layout
  .ant-pagination:not(.ant-pagination-mini)
  .ant-pagination-item-link:hover,
.manage-layout
  .ant-pagination:not(.ant-pagination-mini)
  .ant-pagination-item-active {
  border: 1px solid #2a61ff;
  color: #2a61ff;
  background-color: #fff;
}
.manage-layout
  .ant-pagination:not(.ant-pagination-mini)
  .ant-pagination-disabled
  .ant-pagination-item-link {
  border: 1px solid #d9d9d9;
}
.manage-layout
  .ant-pagination:not(.ant-pagination-mini)
  .ant-pagination-disabled
  .ant-pagination-item-link
  span {
  cursor: not-allowed;
}
.manage-layout .ant-pagination .ant-pagination-item-active a {
  color: #2a61ff;
}
.manage-layout
  .ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-link,
.manage-layout
  .ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-link,
.manage-layout
  .ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-link:hover,
.manage-layout
  .ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-link:hover {
  border: none;
}

.manage-layout .ant-layout-sider {
  z-index: 3;
  background: rgb(102, 139, 252) !important;
}

.manage-layout .list-search-input {
  padding: 4px 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  min-width: 350px;
}

.manage-layout .tab-action {
  justify-content: flex-end;
}
@media screen and (min-width: 768px) {
  .manage-layout .tab-action {
    position: absolute;
    right: 0;
    top: -40px;
    display: flex;
    align-items: center;
  }
}
