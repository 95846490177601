body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*luckysheet style*/
#luckysheet-rightclick-menu.disabled {
  display: none !important;
}
#luckysheet-search-formula.luckysheet-modal-dialog #formulaTypeList {
  width: 100%;
}
#luckysheet-search-formula-parm.luckysheet-modal-dialog .parmListBox {
  width: 100%;
}
#luckysheet-search-formula-parm.luckysheet-modal-dialog .parmBox {
  display: flex;
}
#luckysheet-search-formula-parm .parmBox .txt input {
  background-color: transparent;
}
.luckysheet-freezebar-hover .luckysheet-freezebar-horizontal-drop-bar,
.luckysheet-freezebar-hover .luckysheet-freezebar-horizontal-drop-title {
  height: 2px !important;
  background-color: #5d88db;
}
.luckysheet-freezebar-hover .luckysheet-freezebar-vertical-drop-bar,
.luckysheet-freezebar-hover .luckysheet-freezebar-vertical-drop-title {
  width: 2px !important;
  background-color: #5d88db;
}
.luckysheet-freezebar-horizontal-drop-bar,
.luckysheet-freezebar-horizontal-drop-title {
  height: 1px !important;
  background-color: #1677ff !important;
}
.luckysheet-freezebar-vertical-drop-bar,
.luckysheet-freezebar-vertical-drop-title {
  width: 1px !important;
  background-color: #1677ff !important;
}
body.language-zh .luckysheet-cols-rows-shift-right,
body.language-zh .luckysheet-cols-rows-shift-left,
body.language-zh .luckysheet-cols-rows-shift-word {
  margin-left: -3px;
  margin-right: -3px;
}
#luckysheet-cols-rows-add input {
  margin-right: 5px;
}
.luckysheet-cols-menu,
.luckysheet-cols-menu-style {
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border: none;
  border-radius: 4px;
  padding: 8px;
  background-color: #fff;
}
.luckysheet-cols-menu-style .ant-dropdown-menu {
  padding: 0;
}
#luckysheet-rightclick-menu {
  min-width: 150px;
}
#luckysheet-rightclick-menu
  .luckysheet-cols-menuitem
  .luckysheet-cols-menuitem-content,
#luckysheet-filter-menu .luckysheet-cols-menuitem-content {
  padding: 3px 0px 3px 1px;
}
.luckysheet-cols-menu .luckysheet-cols-menuitem,
.luckysheet-cols-menu-style .ant-dropdown-menu-item {
  padding: 6px 8px !important;
  font-size: 13px !important;
  border-radius: 4px;
}
.luckysheet-cols-menu
  .luckysheet-cols-menuitem
  .luckysheet-cols-menuitem-content {
  color: rgba(0, 0, 0, 0.75) !important;
}
.luckysheet-cols-menu .luckysheet-cols-menuitem:hover {
  background-color: #f5f5f5;
}
.luckysheet-cols-menu .luckysheet-cols-menuitem .luckysheet-submenu-arrow {
  margin-right: 10px;
}
#luckysheet-icon-freezen-menu-menuButton .luckysheet-cols-menuitem .icon,
#luckysheet-icon-autofilter-menuButton .luckysheet-cols-menuitem .icon {
  display: none !important;
}
.luckysheet-cols-menu .luckysheet-menuseparator {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.luckysheet-cols-menu .luckysheet-filter-byvalue .ListBox {
  overflow-x: auto !important;
  margin: -5px 0;
}
#luckysheet-icon-textwrap-menu-menuButton.luckysheet-cols-menu {
  width: 160px !important;
}
.luckysheet-toolbar-menu-button {
  margin-right: 3px;
}
.luckysheet-toolbar-menu-button:hover {
  background-color: rgba(0, 0, 0, 0.06) !important;
}
.luckysheet-toolbar-menu-button button:hover {
  background-color: transparent !important;
}
#luckysheet-freezen-btn-horizontal .luckysheet-toolbar-menu-button-caption {
  margin-right: 5px;
}
#luckysheet-sheet-list
  .luckysheet-cols-menuitem
  .luckysheet-cols-menuitem-content {
  padding-left: 0 !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
#luckysheet-sheet-list .luckysheet-cols-menuitem-content .icon {
  margin-left: 0;
  margin-right: 5px;
}
#luckysheet-filter-menu .luckysheet-submenu-arrow {
  margin-right: 0;
}
#luckysheet-filter-menu .ListBox input.luckysheet-mousedown-cancel {
  margin-right: 5px;
  margin-bottom: 5px;
}
#luckysheet-filter-menu .luckysheet-mousedown-filter-byvalue-btn div,
#luckysheet-filter-menu div.luckysheet-filter-byvalue-input-icon {
  right: 3px;
}
#luckysheet-filter-menu #luckysheet-filter-byvalue-input {
  width: 100%;
  min-width: 190px;
}
#luckysheet-filter-menu .btn {
  font-size: 13px;
}
#luckysheet-search-replace .btnBox button {
  margin-left: 8px !important;
}
.fa-times:before {
  content: "\f00d";
}
.luckysheet-modal-dialog-buttons button {
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 14px;
  margin: 3px 0 !important;
}
.luckysheet-modal-dialog-title-close .fa {
  font-size: 1.2em;
}
#luckysheet-search-replace .tabBox span {
  margin-right: 2px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 6px 6px 0px 0px;
  border: 1px solid #d9d9d9;
  border-bottom: none;
  padding: 4px 20px;
  width: 7em;
  margin-bottom: -1px;
}
#luckysheet-search-replace .tabBox span.on {
  color: #2a61ff;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-bottom: 1px solid #fff;
}
#luckysheet-search-replace .ctBox {
  padding: 17px 23px;
}
#luckysheet-search-replace .inputBox {
  height: auto;
}
#luckysheet-search-replace .inputBox .checkboxs input {
  margin-top: 1px !important;
  margin-right: 5px;
}
#luckysheet-search-replace .inputBox .checkboxs {
  height: auto;
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  flex-wrap: wrap;
}
#luckysheet-search-replace .inputBox .checkboxs div {
  display: flex;
}
#luckysheet-search-replace .inputBox .textboxs {
  height: auto;
}
#luckysheet-search-replace .inputBox .textboxs input {
  width: 100%;
  margin: 5px 0;
  border: 1px solid #d9d9d9;
}
#luckysheet-search-replace .ctBox {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid #d9d9d9;
}

#luckysheet-rows-h {
  margin-top: -6px;
  padding-bottom: 7px;
}
.luckysheet-modal-dialog-mask {
  background-color: rgb(28, 29, 33);
}
.luckysheet-modal-dialog {
  padding: 0;
  min-width: min(calc(100% - 30px), 500px);
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(28, 29, 33, 0.2);
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
    0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
}
.luckysheet-modal-dialog-title {
  padding: 16px 20px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 0 !important;
}
#luckysheet-search-replace .luckysheet-modal-dialog-title {
  padding: 0;
}
.luckysheet-modal-dialog-content {
  padding: 16px 20px;
}
.luckysheet-modal-dialog-buttons {
  margin-top: 0;
  padding: 10px 20px;
  border-top: 1px solid #f0f0f0;
}
.luckysheet-modal-dialog-content {
  min-width: 100% !important;
}
.luckysheet-modal-dialog-title-close {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.luckysheet-modal-dialog-buttons {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.luckysheet-modal-dialog-buttons button {
  margin-left: 8px !important;
  padding: 4px 15px;
}
.luckysheet-modal-dialog-title {
  margin-bottom: 28px;
}
.luckysheet-modal-dialog table {
  width: 100%;
}
#luckysheet-bottom-controll-row span {
  display: none;
}
#luckysheet-bottom-controll-row input {
  display: none;
}
#luckysheet-bottom-controll-row {
  width: 100%;
}
#luckysheet-bottom-add-row {
  background-color: transparent;
  border: none !important;
  box-shadow: none;
  outline: none;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
  text-align: left;
}
#luckysheet-bottom-add-row:hover {
  color: rgba(0, 0, 0, 1);
}
.luckysheet-toolbar-button,
.luckysheet-toolbar-menu-button {
  font-size: 14px;
}
#luckysheet-sort-dialog .luckysheet-sort-dialog-additem {
  color: #2a61ff;
  text-decoration: none !important;
  font-size: 14px;
}
#luckysheet-sort-dialog table tr td {
  border-top: none;
  padding-left: 0;
  padding-right: 10px;
  padding-bottom: 10px;
  vertical-align: bottom;
}
#luckysheet-sort-dialog table tr td:nth-last-child(1) {
  width: 14px;
  padding: 0;
  padding-bottom: 16px;
}
.luckysheet-sort-modal {
  font-size: 14px;
}
#luckysheet-sort-dialog table tr td select {
  width: 100%;
  max-width: 100%;
  padding: 4px 6px;
  border: 1px solid #b9b9b9;
  color: #444444;
}
#luckysheet-sort-dialog .divider {
  display: none;
}
#luckysheet-dropCell-icon {
  background-color: #fff !important;
  padding: 4px 6px !important;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
#luckysheet-dropCell-icon:hover {
  background-color: rgba(73, 120, 255, 0.1) !important;
}
#luckysheet-dropCell-icon #icon_dropCell {
  background-image: url("../images/icons/icon_dropCell_new.png") !important;
  width: 28px !important;
}

/*global style*/
.ant-form-item.hidden-item-with-rules .ant-form-item-control-input {
  display: none;
}
.ant-form-item.hidden-item-with-rules {
  margin-bottom: 0;
}
.form-item-row .ant-form-item-row {
  flex-direction: row !important;
  align-items: center;
}
.form-item-row .ant-form-item-row .ant-form-item-label {
  padding-bottom: 0 !important;
  padding-right: 8px;
}
.form-item-row .ant-form-item-row .ant-form-item-control {
  flex: 1;
}
.ant-table-row .table-column-minwidth-7 {
  min-width: 7em;
}

.ant-table-row .draggable {
  cursor: move;
  cursor: grab;
  font-size: 1.2rem;
  transform: rotate(90deg);
}

.gu-mirror {
  background-color: rgba(16, 142, 233, 0.15);
  cursor: grabbing;
}
.field-list-dragula-table table {
  table-layout: fixed !important;
}
.field-list-dragula-table-row.gu-mirror {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.field-list-dragula-table-row.gu-mirror .ant-table-cell {
  flex: 1;
  padding: 8px 8px;
  font-size: 14px;
}
.field-list-dragula-table td.drag-column {
  width: 3rem !important;
  text-align: center;
  flex: 0 !important;
}
.field-list-dragula-table-row.gu-mirror .ant-table-cell.drag-column {
  width: 3rem !important;
  text-align: center;
  flex: 0 0 auto !important;
}
.draggable-item {
  z-index: 99999;
}

.compare-table tr.ant-table-row:hover td {
  background-color: transparent !important;
}
.compare-table tbody td.ant-table-cell {
  padding: 0 8px !important;
  height: 1.8em !important;
  border-bottom: none !important;
  background-color: transparent !important;
}
.compare-table th.ant-table-cell {
  border-bottom: none !important;
}
.compare-table tr.row-delete {
  background-color: #ffebe9 !important;
}
.compare-table tr.row-new {
  background-color: #e6ffec !important;
}
.compare-table tr.row-changed {
  background-color: #fffbe6 !important;
}
.compare-table .row-changed .cell-changed {
  background-color: #ffe58f !important;
}
.compare-table .ant-table-cell {
  white-space: nowrap !important;
  min-width: 6em;
  border: none !important;
  border-start-start-radius: 0px !important;
}
.compare-table table {
  border-radius: 0px !important;
}
.compare-table .ant-table-cell-fix-left {
  min-width: auto;
}
.compare-table .ant-table-pagination {
  display: none;
}
.compare-table tr.row-selected td {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.compare-new-table .ant-table-cell-fix-left {
  border-left: 1px solid #dddddd !important;
}
.compare-checkbox .ant-col {
  /*  overflow: hidden;*/
}
.compare-checkbox label {
  width: 100%;
  display: flex;
}
.compare-checkbox label span {
  display: inline-block;
  width: 100%;
  text-align: left;
}
.compare-checkbox label .ant-checkbox {
  width: auto;
}

.dataset-template-card {
  padding: 1.5em 1em 1em 1em;
  border-radius: 0.5em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border: 1px solid #d9d9d9;
  overflow: hidden;
}
.dataset-template-card:hover {
  border: 1px solid #2a61ff;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}
.dataset-template-card .actions {
  display: flex;
  justify-content: space-around;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(255, 255, 255, 1) 20%
  );
  transition: all 1s;
}
.dataset-template-card .actions button {
  flex: 1;
}
.dataset-template-card:hover .actions {
  opacity: 1;
}
.sheet-action-item button {
  background-color: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0 0.7em !important;
  margin: 0 0.3em !important;
}
.sheet-action-item .ant-select-arrow {
  color: #000000;
}
.sheet-action-item .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 0.7em !important;
  margin: 0 0.3em !important;
}
.sheet-action-item .ant-select-selector:hover {
  color: #1677ff;
}
.sheet-actions .sheet-action-item:nth-of-type(n + 2):before {
  content: "|";
  color: rgba(0, 34, 101, 0.15);
  font-size: 1.2em;
}
.dataset-data-actions {
  width: 5em;
  background: white;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0.5em 0;
  min-height: 100%;
  height: calc(100vh - 146px);
  overflow: auto;
}
@media screen and (max-width: 992px) {
  .dataset-data-actions {
    height: calc(100vh - 140px);
  }
}
.dataset-data-actions .action-item button {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 100%;
  padding: 0.5em 0.3em;
  box-sizing: border-box;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  transition: none;
  font-size: 0.9em;
}
.dataset-data-actions .action-item {
  text-align: center;
  padding: 0.4em 0;
  margin: 0 0.5em;
}
.dataset-data-actions .action-item button span {
  margin-left: 0;
  margin-inline-end: 0 !important;
  margin-right: 0;
}
.dataset-data-actions .action-item:nth-of-type(n + 2) {
  border-top: 1px solid rgba(0, 34, 101, 0.15);
  border-image: linear-gradient(
      to right,
      #fff 18%,
      rgba(0, 34, 101, 0.15) 18.1%,
      rgba(0, 34, 101, 0.15) 81.9%,
      #fff 82%
    )
    1;
}
#dataset-action-version-manage.action-item {
  margin: 0;
}
#dataset-action-version-manage .version-manage-box {
  position: absolute;
  background-color: #ffffff;
  top: 0;
  right: 4.95em;
  width: min(calc(90vw - 5em), 320px);
  min-height: 10em;
  max-height: calc(100vh - 200px);
  display: none;
  z-index: 1000;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  flex-direction: column;
}
#dataset-action-version-manage:hover .version-manage-box,
#dataset-action-version-manage .version-manage-box:hover {
  display: flex;
}
#floating-layer:has(.dataset-version-item-manage-menu:hover)
  + #root
  #dataset-action-version-manage
  .version-manage-box {
  display: flex;
}
#dataset-action-version-manage .version-manage-box .actions {
  position: relative;
}
#dataset-action-version-manage .version-manage-box .actions .actions-active {
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
}
#dataset-action-version-manage
  .version-manage-box
  .actions:hover
  .actions-active {
  visibility: visible;
}
#dataset-action-version-manage .version-manage-box .actions:hover .active {
  visibility: hidden;
}
#dataset-action-version-manage
  .version-manage-box
  .version-item
  .version-item-box {
  background-color: #ffffff;
  padding: 10px 1.25em;
  text-align: left;
  cursor: pointer;
  display: flex;
  width: 100%;
}
#dataset-action-version-manage
  .version-manage-box
  .version-item
  .version-item-box:hover {
  background-color: rgba(42, 97, 255, 0.03);
}

.select-role .ant-select-selection-item {
  height: 32px !important;
  text-overflow: clip !important;
}

.nc-header,
.nc-footer {
  display: none !important;
}

.mantine-Popover-dropdown {
  z-index: 999 !important;
}

.tables-sheet-form .ant-form-item-label {
  text-align: right;
  flex: 0 0 30% !important;
  padding: 0;
  padding-right: 1em;
}
.tables-sheet-form .ant-form-item {
  margin-bottom: 22px;
}
.tables-sheet-form .ant-form-item-control {
  flex: 1 !important;
}
.tables-sheet-form .ant-form-item-explain-error {
  font-size: 0.9em;
}

.tables-sheet-form .ant-input-disabled,
.tables-sheet-form .ant-input-number-disabled,
.tables-sheet-form .ant-input-number-disabled .ant-input-number-input,
.tables-sheet-form .ant-select-disabled .ant-select-selector {
  background: transparent !important;
  color: rgba(0, 0, 0, 0.88) !important;
  border: none !important;
  padding: 0 !important;
}
.tables-sheet-form .ant-select-disabled .ant-select-arrow {
  display: none;
}

.table-link {
  color: #2a61ff !important;
  text-decoration: none;
  background-color: transparent;
}
.table-link:hover {
  color: #638bff !important;
  text-decoration: underline;
}

.title-link {
  color: #2a61ff !important;
  text-decoration: none;
  background-color: transparent;
}

.ant-modal .ant-modal-content {
  padding: 0;
}
.ant-modal .ant-modal-header {
  padding: 16px 20px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 0;
}
.ant-modal .ant-modal-body,
.ant-modal-confirm.ant-modal .ant-modal-body {
  padding: 16px 20px;
}
.ant-modal .ant-modal-footer {
  margin-top: 0;
  padding: 10px 20px;
  border-top: 1px solid #f0f0f0;
}
.full-label label {
  width: 100%;
}

.ant-upload-wrapper .ant-upload-drag {
  background-color: transparent;
}

.btn-primary {
  background-color: #2a61ff;
}
.btn-primary:hover {
  background-color: #638bff !important;
  border-color: #638bff !important;
}

.description_tooltip.ant-tooltip .ant-tooltip-inner {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
  padding: 1em 1.2em;
  font-size: 0.95em;
}
.description_tooltip.ant-tooltip .ant-tooltip-arrow:before {
  background-color: #fff;
}
.ant-tooltip ::selection {
  color: #2a61ff;
  background: transparent;
}

.ant-pagination-options-size-changer .ant-select-selector {
  cursor: pointer !important;
}

.template-view-tree .ant-tree-node-selected {
  background-color: #2a61ff !important;
  color: #fff !important;
}

.select-chart-type .rc-virtual-list-holder-inner {
  flex-direction: row !important;
  flex-wrap: wrap;
  padding: 0 12px;
}
.select-chart-type .ant-select-item {
  padding: 5px 0;
}
.select-chart-type .ant-select-item-group {
  width: 100%;
  padding: 5px 6px;
}
.select-chart-type .ant-select-item-option {
  margin: 5px 6px;
  width: 6em;
  height: 6em;
  padding: 5px;
  border: 1px solid #f5f5f5;
}
.select-chart-type .ant-select-item-option-active {
  background: transparent;
}
.select-chart-type .ant-select-item-option:hover {
  background: transparent;
  border: 1px solid #e6f7ff;
}
.select-chart-type .ant-select-item-option-selected {
  background: transparent;
  border: 1px solid #1890ff !important;
}

.chart-actions .ant-dropdown-menu-item {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.chart-view-list {
  margin-right: -1em;
}
.chart-view-list .chart-view-item {
  margin-right: 1em;
  margin-bottom: 1em;
  width: 100%;
  resize: both;
  overflow: auto;
  border-radius: 2px;
  height: calc(25vh + 6em);
  float: left;
  min-height: calc(25vh + 6em);
  max-width: calc(100% - 1em);
  min-width: calc(100% - 1em);
}
@media screen and (min-width: 1200px) {
  .chart-view-list .chart-view-item {
    width: calc(50% - 1em);
    min-width: calc(50% - 1em);
  }
}

.chart_view .ant-collapse-content-box {
  padding: 0 !important;
}

.chart_view .ant-collapse-header {
  padding: 0 0 16px 0 !important;
}

.chart_view .ant-collapse-expand-icon svg {
  transform: rotate(-90deg);
}

.chart_view .ant-slider {
  margin: 11px 10px 11px 0px;
}

.ant-dropdown-menu-item-disabled {
  background-color: transparent !important;
  color: #00000040 !important;
}

.minWidth0 {
  min-width: 0 !important;
}

/*antd style*/
.ant-dropdown .ant-dropdown-menu {
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  padding: 0;
  min-width: 14em;
  max-width: 25em;
  max-height: 23.5em;
  overflow: auto;
}

.ant-dropdown.minWidth0 .ant-dropdown-menu {
  min-width: 0 !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  border-radius: 0;
  padding: 12px 1em;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  color: #2a61ff;
  background: rgba(42, 97, 255, 0.06);
}

.ant-dropdown-menu .ant-dropdown-menu-item-divider {
  margin: 4px 14px !important;
}

.workspace-manage-page .ant-tabs-nav {
  margin-bottom: 0;
}

.d-flex {
  display: flex;
}

.page-form-actions {
  display: flex !important;
  justify-content: flex-end !important;
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
  padding: 14px 24px !important;
  width: 100% !important;
  background: #fff !important;
  box-shadow: 0px -1px 10px 0px rgba(0, 0, 0, 0.08) !important;
  border-radius: 0px 0px 6px 6px !important;
  z-index: 2 !important;
}

.no-resize textarea {
  resize: none;
}

#luckysheet-icon-seachmore-menuButton
  .luckysheet-mousedown-cancel:not(:nth-child(-1n + 2)) {
  display: none;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar:horizontal {
  height: 4px;
}

::-webkit-scrollbar-track {
  display: none;
}

::-webkit-scrollbar-thumb {
  background: lightgray;
}

.ant-upload-wrapper.hide-file .ant-upload-list {
  display: none;
}

.workspace-manage .ant-dropdown-menu {
  padding-top: 8px;
}
.workspace-manage .actions .actions-active {
  position: absolute;
  top: 0;
  right: 0;
  visibility: hidden;
}
.workspace-manage .actions:hover .actions-active {
  visibility: visible;
}
.workspace-manage .actions:hover .active {
  visibility: hidden;
}

.full-screen-modal {
  width: 100vw !important;
  height: 100vh;
  margin: 0;
  padding: 0;
  top: 0;
  max-width: none;
}

.full-screen-modal .ant-modal-content {
  width: 100%;
  min-height: 100%;
}

.full-screen-modal .ant-modal-body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}